
import { Link } from '../types/link';

import {
  ECARDS_LINK,
  HOWITWORKS_LINK,
  OCCASIONS_LINK,
  GIFTS_LINK,
  GROUPCARDS_LINK,
  POSTS_LINK,
  ABOUT_LINK
} from '../constants/links';

const links: Link[] = [
  { label: 'How it works', link: HOWITWORKS_LINK, index: 0, mobileIndex: 0 },
  { label: 'eCards', link: ECARDS_LINK, index: 1, mobileIndex: 1 },
  { label: 'Group cards', link: GROUPCARDS_LINK, index: 2, mobileIndex: 2 },
  { label: 'Occasions', link: OCCASIONS_LINK, index: 3, mobileIndex: 3 },
  { label: 'Gifts', link: GIFTS_LINK, index: 4, mobileIndex: 4 },
  { label: 'Blog', link: POSTS_LINK, index: 5, mobileIndex: 5 },
  { label: 'About Us', link: ABOUT_LINK, index: 6, mobileIndex: 6 }
];

export default links;