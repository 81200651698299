import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';

import MetaTags from '../components/MetaTags';

import { theme } from '../styles/theme';

import Background from '../components/Background';
import Header from '../components/Header2';
import MeaningfullGroupGifts from '../sections/marketing/MeaningfullGroupGifts';
import Companies from '../sections/marketing/Companies';
import FutureOfCelebrating from '../sections/marketing/FutureOfCelebrating';
import CuratedGifts from '../sections/marketing/CuratedGifts';
import GroupCard from '../sections/marketing/GroupCard';
import LoveIllume from '../sections/marketing/LoveIllume';
import HowItWorks from '../sections/marketing/HowItWorks';
import Join from '../sections/Join';
import Footer from '../components/FooterMain';
import data2 from '../data/FooterSecondaryLinks';
import data from '../sections/marketing/data';
import { EXPLORE_GIFTS, GIFTS_LINK } from '../constants/links';
import { all_type_of_carousel_data } from '../sections/marketing/data/products_and_notes_carousel_data';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const Marketing: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating.'
        }
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <MeaningfullGroupGifts wrap flippingTexts={data.headingTexts} />
        <Companies />
        <FutureOfCelebrating topics={data.topics} />
        <CuratedGifts
          subtitle='Thoughtful. Sustainable. Locally-sourced.'
          ctaAction={{
            onClick: () => {
              window.location.href = EXPLORE_GIFTS;
            },
            name: 'Explore Gifts',
          }}
          title='Beautiful, curated gifts you can’t find anywhere else'
          data={all_type_of_carousel_data}
        />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <HowItWorks />
        <Join />
        <Footer links={data2} />
      </Layout>
    </ThemeProvider>
  );
};

export default Marketing;
