import styled from 'styled-components';

export const Layout = styled.section<{ isMain?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 32px 0 32px;
  width: 100%;
  ${(props) => props.isMain && 'z-index: 3;'}

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px;
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    ${(props) => (props.isMain ? 'padding: 40px;' : 'padding: 40px 0;')}
    width: 100%;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 128px;
  margin: 16px 0 24px 0;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    bottom: 0;
    flex-direction: row;
    height: 48px;
    position: absolute;
    width: auto;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  margin: 4px 0;

  img {
    margin: 0 4px;
  }

  a {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 8px;

    a {
      white-space: nowrap;
    }

    img {
      max-height: 24px;
      max-width: 24px;
    }
  }
`;

export const Copyright = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 24px 4px 0;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CopyrightAndNavLinks = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 8px 0 0 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    bottom: 0;
    flex-direction: row;
    font-size: 14px;
    height: 48px;
    justify-content: space-between;
    line-height: 18px;
    margin: 16px 0 24px 0;
    position: absolute;
    right: 48px;
  }
`;

export const Divider = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.darkGray};
  margin: 0 24px 24px 0;

  @media ${(props) => props.theme.breakpoints.laptop} {
    bottom: 0;
    flex-direction: row;
    font-size: 14px;
    height: 24px;
    justify-content: space-between;
    line-height: 18px;
    margin: 0 8px 24px 0;
    right: 48px;
  }
`;

export const NavLinks = styled.div<{ marginOffset?: boolean }>`
  display: flex;
  ${(props) =>
    props.marginOffset &&
    `
    margin-left: -8px;

    @media ${props.theme.breakpoints.laptop} {
      margin-left: 0;
    }
  `}
`;

export const NavLink = styled.a`
  line-height: 24px;
  margin: 4px 0;
  padding: 0 8px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 14px;
    line-height: 18px;
  }
`;
