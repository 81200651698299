import React, { FC, useEffect, useMemo, useState } from 'react';
import useFormFactors from '../helpers/useFormFactors';
import { Link } from '../types/link';
import { TERMS_LINK, PRIVACY_LINK } from '../constants/links';
import {
  Copyright,
  CopyrightAndNavLinks,
  Divider,
  Layout,
  LinkContainer,
  Links,
  NavLink,
  NavLinks,
} from '../sections/common/FooterWithNavStyles';

interface Props {
  links: Link[];
}
const Footer: FC<Props> = ({ links }) => {
  const formFactors = useFormFactors();
  const year: number = new Date().getFullYear();
  const [usableLinks, setUsableLinks] = useState<Link[]>(links);

  useEffect(() => {
    if (formFactors.laptopAll) {
      setUsableLinks([...links].sort((a, b) => a.index - b.index));
    } else {
      setUsableLinks([...links].sort((a, b) => a.mobileIndex - b.mobileIndex));
    }
  }, [formFactors.laptopAll]);

  return (
    <Layout isMain={true}>
      <Links>
        {usableLinks.map((link: Link) => (
          <LinkContainer key={link.link}>
            {link.icon ? <img src={link.icon} /> : null}
            <a href={link.link}>{link.label}</a>
          </LinkContainer>
        ))}
      </Links>
      <CopyrightAndNavLinks>
        <Copyright>© {year}. All rights reserved. Dedicated to those who shine.</Copyright>
        {formFactors.laptopAll ? <Divider /> : null}
        <NavLinks marginOffset={true}>
          <NavLink href={TERMS_LINK}>Terms</NavLink>
          <NavLink href={PRIVACY_LINK}>Privacy</NavLink>
        </NavLinks>
      </CopyrightAndNavLinks>
    </Layout>
  );
};

export default Footer;